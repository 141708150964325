import * as React from "react"
import { Helmet } from "react-helmet"

import StudentHeader from "../../../../../components/student-header"
import Footer from "../../../../../components/footer"
import ArticleHeader from "../../../../../components/article-header"

import "../../../../../scss/site.scss"
//import StickyCta from "../../../../../components/sticky-cta"

const Letter = props => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Maj Britts Brev | Demokrati Pågår </title>
        <link
          rel="canonical"
          href="https://www.demokratipagar.se/historiska-perspektiv/utan-demokratiska-rattigheter/maj-britt-johansson/maj-britts-brev"
        ></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:url"
          content="https://www.demokratipagar.se/historiska-perspektiv/utan-demokratiska-rattigheter/maj-britt-johansson/maj-britts-brev"
        ></meta>
        <meta property="og:title" content="Maj Britts Brev"></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <div className="c-body">
        <StudentHeader />
        <div role="main" id="main" className="o-main c-main-background">
          <article>
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-lg-9">
                  <ArticleHeader title="Maj-britts brev till systern:" />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-lg-7 c-article-content">
                  <p>Västervik 6 juni 1948</p>
                  <p>Kära syster Gertrud,</p>
                  <p>
                    Stort tack för paketen som jag fick av dig. Det var roligt
                    att få något.
                  </p>
                  <p>
                    Vad gör du i sommar? Kan du komma och hälsa på mig? Det var
                    så länge sedan vi sågs. Då var jag 8 år. Nu är jag 19 år.
                  </p>
                  <p>
                    Har du hört något från dom andra syskonen? Jag har inte hört
                    från dem på länge. Du är den enda som skickar brev till mig.
                  </p>
                  <p>
                    Jag har fått ett paket från en tant i Eksjö. Hon är så snäll
                    mot mig. Hon ska snart komma och hälsa på. Hon tänker mycket
                    mer på mig än vad mina syskon gör.
                  </p>
                  <p>
                    Jag skulle bli glad om jag fick åka hem och hälsa på mina
                    syskon. Kan du hjälpa mej att komma ut från sjukhuset? Det
                    är inte roligt att vara instängd när man är så ung.
                  </p>
                  <p>
                    Hur hade du det på midsommarafton? Här på sjukhuset hade de
                    midsommarfest. Men jag fick inte gå. Doktorn sa att jag var
                    för sjuk för att vara med. Jag hoppas jag blir frisk så jag
                    kan vara med en annan gång.
                  </p>
                  <p>
                    Många hälsningar från din syster Maj-Britt.
                    <br />
                    Skriv snart!
                  </p>
                </div>
              </div>
            </div>
          </article>
        </div>
        <Footer />
        {/*<StickyCta />*/}
      </div>
    </>
  )
}

export default Letter
